import PagedFilterBase from "./PagedFilterBase";
import BusinessBranchFeedbackType from "../models/api/businesses/branches/BusinessBranchFeedbackType";

export default class BusinessBranchFeedbackFilter extends PagedFilterBase {
    BusinessBranchId?: string;
    Type?: BusinessBranchFeedbackType;

    constructor(init?: Partial<BusinessBranchFeedbackFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}
